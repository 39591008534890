import { configure, runInAction, action, observable, computed } from 'mobx'
import FlashMessageStore from 'flash-messages/FlashMessageStore'
import {
  sessionStorage,
  createEncryptedStorage,
  localStorage,
} from 'misc/storage'
import TaxfyleAPI from 'misc/TaxfyleAPI'
import { retryOnNetworkFailure } from 'utils/errorUtil'
import AuthStore from 'auth/AuthStore'
import MultiFactorAuthStore from 'auth/MultiFactorAuthStore'
import UserStore from 'iam/UserStore'
import UserDataStore from 'iam/UserDataStore'
import MemberStore from 'iam/MemberStore'
import RoleStore from 'iam/RoleStore'
import SkillStore from 'iam/SkillStore'
import SkillTagStore from 'iam/SkillTagStore'
import WorkspaceStore from 'iam/WorkspaceStore'
import WorkspaceSelectorStore from 'iam/WorkspaceSelectorStore'
import PermissionStore from 'iam/PermissionStore'
import TeamStore from 'iam/TeamStore'
import TeamMemberStore from 'iam/TeamMemberStore'
import TeamRoleStore from 'iam/TeamRoleStore'
import TranslationStore from 'iam/TranslationStore'
import TeamManagementStore from 'iam/TeamManagementStore'
import InvitationStore from 'iam/InvitationStore'
import TermsOfUseStore from 'iam/TermsOfUseStore'
import SessionStore from 'session/SessionStore'
import ProjectStore from './ProjectStore'
import EventStore from './EventStore'
import MilestoneStore from 'jobs/MilestoneStore'
import QuestionStore from 'jobs/QuestionStore'
import PromptStore from 'jobs/PromptStore'
import JobProgressionStore from 'jobs/JobProgressionStore'

import JobTicketStore from 'jobs/JobTicketStore'
import ScopeChangeRequestStore from 'jobs/ScopeChangeRequestStore'
import ScopeChangeRequestBreakdownStore from './ScopeChangeRequestBreakdownStore'
import MessageStore from 'messaging/MessageStore'
import RoomStore from 'messaging/rooms/RoomStore'
import CallStore from 'messaging/calling/CallStore'
import { CallingConversationViewModel } from 'messaging/calling/CallingConversationViewModel'

import CouponStore from 'billing/CouponStore'
import UserCouponStore from 'billing/UserCouponStore'
import UpdateJobDialogStore from './ui/UpdateJobDialogStore'
import UpdateDeadlineDialogStore from './ui/UpdateDeadlineDialogStore'
import BillingStore from 'billing/BillingStore'
import DocumentStore from 'documents/DocumentStore'
import DocumentTagStore from 'documents/DocumentTagStore'
import DocumentVersionStore from 'documents/DocumentVersionStore'
import DocumentAccessStore from 'documents/DocumentAccessStore'
import NotificationActionStore from './NotificationActionStore'
import NotificationStore from 'notifications/NotificationStore'
import NotificationPreferencesStore from 'notifications/NotificationPreferencesStore'
import MultiWorkspaceNotificationStore from 'notifications/MultiWorkspaceNotificationStore'
import ProjectDetailsStore from './ui/ProjectDetailsStore'
import ProjectWizardStore from './ui/ProjectWizardStore'
import ResetPasswordScreenStore from './ui/ResetPasswordScreenStore'
import DocumentsScreenStore from './ui/DocumentsScreenStore'
import SoundStore from 'sound/SoundStore'
import SoundPlayer from 'sound/SoundPlayer'
import DesktopNotificationStore from 'desktop-notifications/DesktopNotificationStore'
import CouponsScreenStore from './ui/CouponsScreenStore'
import ProfileEditorStore from './ui/ProfileEditorStore'
import ProjectsScreenStore from './ui/ProjectsScreenStore'
import BillingScreenStore from './ui/BillingScreenStore'
import GettingStartedStore from './ui/GettingStartedStore'
import ChangeEmailDialogStore from './ui/ChangeEmailDialogStore'
import TermsDialogViewModel from './ui/TermsDialogStore'
import HandoffDialogViewModel from './ui/HandoffDialogStore'
import TrackingStore from './ui/TrackingStore'
import { ConfirmDialogState } from 'components/ConfirmDialog'
import JobProgressionTimelineDialogStore from './ui/JobProgressionTimelineDialogStore'
import CallRootViewModel from '../screens/ProjectDetails/Calling/CallRootViewModel'
import env from '../misc/env'
import { createIamAPI } from '@taxfyle/web-commons/lib/iam/API'
import { UserIdLookup } from '@taxfyle/web-commons/lib/iam/UserIdLookup'
import { createMessagingAPI } from '@taxfyle/web-commons/lib/messaging/API'
import { pageActivityStream } from '@taxfyle/web-commons/lib/utils/domUtil'
import { WorkRealtime } from '@taxfyle/web-commons/lib/work-realtime/WorkRealtime'
import { share, find } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import {
  MediaSettingsDialogViewModel,
  MediaSettingsStore,
} from '../components/MediaSettingsDialog/MediaSettingsDialogViewModel'
import PromptDialogStore from 'jobs/PromptDialogStore'
import RejectDraftDialogStore from 'jobs/RejectDraftDialogStore'
import ApproveDraftDialogStore from 'jobs/ApproveDraftDialogStore'
import DraftStore from 'drafts/DraftStore'
import LegendSettingsStore from './LegendSettingsStore'
import ScopeChangeRequestResponseDialogStore from './ui/ScopeChangeRequestResponseDialogStore'
import ScopeChangeRequestResponseConfirmationDialogStore from './ui/ScopeChangeRequestResponseConfirmationDialogStore'
import { InfoGatheringStore } from './InfoGatheringStore'
import BusinessProfileStore from './BusinessProfileStore'
import { JobRatingStore } from './JobRatingStore'
import DocumentTypeStore from '../documents/DocumentTypeStore'
import ScheduleStore from '../screens/ProjectDetails/Schedule/ScheduleStore'
import ConfirmPhoneNumberDialogStore from '../screens/ProjectDetails/Schedule/ConfirmPhoneNumberDialog/ConfirmPhoneNumberDialogStore'
import ConfirmScheduleDialogStore from '../screens/ProjectDetails/Schedule/ConfirmScheduleDialog/ConfirmScheduleDialogStore'
import { createSignedUrlClient } from 'misc/SignedUrlClient'
import { createMagicLinkClient } from 'misc/MagicLinkClient'
import SignupScreenStore from './ui/SignupScreenStore'
import BookkeepingPlatformConnectionsDialogStore from '../screens/ProjectDetails/Bookkeeping/BookkeepingPlatformConnectionsDialog/BookkeepingPlatformConnectionsDialogStore'
import BookkeepingPlatformSelectionDialogStore from '../screens/ProjectDetails/Bookkeeping/BookkeepingPlatformSelectionDialog/BookkeepingPlatformSelectionDialogStore'
import BookkeepingAddManualConnectionDialogStore from '../screens/ProjectDetails/Bookkeeping/BookkeepingAddManualConnectionDialog/BookkeepingAddManualConnectionDialogStore'
import BookkeepingOtherConnectionDialogStore from '../screens/ProjectDetails/Bookkeeping/BookkeepingOtherConnectionDialog/BookkeepingOtherConnectionDialogStore'
import BookkeepingConfirmAccessDialogStore from '../screens/ProjectDetails/Bookkeeping/BookkeepingConfirmAccessDialog/BookkeepingConfirmAccessDialogStore'
import CreateBookkeepingPlatformConnectionStore from '../store/ui/CreateBookkeepingPlatformConnectionStore'
import BookkeepingPlatformConnectionStore from './BookkeepingPlatformConnectionStore'
import BookkeepingStore from './BookkeepingStore'
import FreshbooksClient from '../misc/FreshbooksAPI'
import ProjectRoomViewModel from '../screens/ProjectRoom/ProjectRoomViewModel'
import DiyStore from './ui/DiyStore'
import IndividualProfileStore from './IndividualProfileStore'
import JobTaxAssistantStore from './JobTaxAssistantStore'
import JobTaxAssistantGreetingDialogStore from './JobTaxAssistantGreetingDialogStore'

configure({ enforceActions: 'observed' })

// WARNING: Changing this will invalidate everything that was
// saved with a previous password.
const storagePassword = '9264P&%oCPyk!U){r<mqc?C8yI]71Q'

export class RootStore {
  @observable
  hydratedWorkspaceDomain = null

  _togglesInitialized = new BehaviorSubject()

  togglesInitialized$ = this._togglesInitialized
    .asObservable()
    .pipe(find(Boolean))

  constructor() {
    const signedUrlClient = createSignedUrlClient()
    const magicLinkClient = createMagicLinkClient(env.IAM_API)

    this.storage = createEncryptedStorage(storagePassword, sessionStorage)
    this.getTime = () => new Date()
    this.pageActivity$ = pageActivityStream().pipe(share())

    const makeOpts = () => ({ rootStore: this, storage: this.storage })
    runInAction(() => {
      this.authStore = new AuthStore({
        ...makeOpts(),
        customProvider: magicLinkClient ?? signedUrlClient,
      })
      this.userStore = new UserStore(makeOpts())
      this.userDataStore = new UserDataStore(makeOpts())
      this.multiFactorAuthStore = new MultiFactorAuthStore(makeOpts())

      this.messagingAPI = createMessagingAPI({
        baseURL: env.MESSAGE_API,
        getToken: this.authStore.getToken,
        token$: this.authStore.token$,
      })

      this.iamAPI = createIamAPI({
        baseURL: env.IAM_API,
        getToken: this.authStore.getToken,
      })

      this.workRealtime$ = WorkRealtime({
        baseURL: env.WORK_DOTNET_API,
        token$: this.authStore.token$,
      })

      this.freshbooksAPI = new FreshbooksClient({
        baseURL: env.FRESHBOOKS_API_EXTERNAL,
        getToken: this.authStore.getToken,
      })

      this.userIdLookup = UserIdLookup(this.iamAPI.users)

      this.workspaceStore = new WorkspaceStore(makeOpts())
      this.sessionStore = new SessionStore(makeOpts())
      this.workspaceSelectorStore = new WorkspaceSelectorStore(makeOpts())
      this.permissionStore = new PermissionStore(makeOpts())
      this.memberStore = new MemberStore(makeOpts())
      this.roleStore = new RoleStore(makeOpts())
      this.skillStore = new SkillStore(makeOpts())
      this.skillTagStore = new SkillTagStore(makeOpts())
      this.teamStore = new TeamStore(makeOpts())
      this.teamMemberStore = new TeamMemberStore(makeOpts())
      this.teamRoleStore = new TeamRoleStore(makeOpts())
      this.teamManagementStore = new TeamManagementStore(makeOpts())
      this.translationStore = new TranslationStore(makeOpts())
      this.invitationStore = new InvitationStore(makeOpts())
      this.termsOfUseStore = new TermsOfUseStore(makeOpts())
      this.api = new TaxfyleAPI({
        authStore: this.authStore,
        workRealtime$: this.workRealtime$,
        togglesInitialized$: this.togglesInitialized$,
      })

      this.documentStore = new DocumentStore(makeOpts())
      this.documentTagStore = new DocumentTagStore(makeOpts())
      this.documentTypeStore = new DocumentTypeStore(makeOpts())
      this.documentAccessStore = new DocumentAccessStore(makeOpts())
      this.documentVersionStore = new DocumentVersionStore(makeOpts())
      this.messageStore = new MessageStore(makeOpts())
      this.roomStore = new RoomStore(makeOpts())
      this.callStore = new CallStore(makeOpts())
      this.callingConversationViewModel = new CallingConversationViewModel(
        makeOpts()
      )
      this.projectStore = new ProjectStore(makeOpts())
      this.jobStore = this.projectStore
      this.milestoneStore = new MilestoneStore(makeOpts())
      this.couponStore = new CouponStore(makeOpts())
      this.userCouponStore = new UserCouponStore(makeOpts())
      this.eventStore = new EventStore(makeOpts())
      this.questionStore = new QuestionStore(makeOpts())
      this.draftStore = new DraftStore(makeOpts())
      this.billingStore = new BillingStore(makeOpts())
      this.promptStore = new PromptStore(makeOpts())
      this.jobTaxAssistantStore = new JobTaxAssistantStore(makeOpts())
      this.jobTaxAssistantGreetingDialogStore =
        new JobTaxAssistantGreetingDialogStore(makeOpts())
      this.jobProgressionStore = new JobProgressionStore(makeOpts())
      this.jobTicketStore = new JobTicketStore(makeOpts())

      this.infoGatheringStore = new InfoGatheringStore(makeOpts())
      this.businessProfileStore = new BusinessProfileStore(makeOpts())

      this.jobRatingStore = new JobRatingStore(makeOpts())
      this.scopeChangeRequestStore = new ScopeChangeRequestStore(makeOpts())
      this.scopeChangeRequestBreakdownStore =
        new ScopeChangeRequestBreakdownStore(makeOpts())
      this.notificationActionStore = new NotificationActionStore(makeOpts())
      this.multiWorkspaceNotificationStore =
        new MultiWorkspaceNotificationStore(makeOpts())
      this.scheduleStore = new ScheduleStore(makeOpts())
      this.confirmPhoneDialogStore = new ConfirmPhoneNumberDialogStore(
        makeOpts()
      )
      this.confirmScheduleDialogStore = new ConfirmScheduleDialogStore(
        makeOpts()
      )
      this.notificationStore = new NotificationStore(makeOpts())
      this.flashMessageStore = new FlashMessageStore(makeOpts())
      this.termsDialogVM = new TermsDialogViewModel(makeOpts())
      this.handoffDialogVM = new HandoffDialogViewModel(makeOpts())
      this.updateJobDialogStore = new UpdateJobDialogStore(makeOpts())
      this.updateDeadlineDialogStore = new UpdateDeadlineDialogStore(makeOpts())
      this.promptDialogStore = new PromptDialogStore(makeOpts())
      this.scopeChangeRequestResponseDialogStore =
        new ScopeChangeRequestResponseDialogStore(makeOpts())
      this.scopeChangeRequestResponseConfirmationDialogStore =
        new ScopeChangeRequestResponseConfirmationDialogStore(makeOpts())
      this.rejectDraftDialogStore = new RejectDraftDialogStore(makeOpts())
      this.approveDraftDialogStore = new ApproveDraftDialogStore(makeOpts())
      this.resetPasswordScreenStore = new ResetPasswordScreenStore(makeOpts())
      this.jobProgressionTimelineDialogStore =
        new JobProgressionTimelineDialogStore(makeOpts())
      this.individualProfileStore = new IndividualProfileStore(makeOpts())
      this.projectDetailsStore = new ProjectDetailsStore(makeOpts())
      this.diyStore = new DiyStore(makeOpts())
      this.projectWizardStore = new ProjectWizardStore(makeOpts())
      this.projectsScreenStore = new ProjectsScreenStore(makeOpts())
      this.documentsScreenStore = new DocumentsScreenStore(makeOpts())
      this.soundStore = new SoundStore(makeOpts())
      this.soundPlayer = new SoundPlayer(localStorage)
      this.desktopNotificationStore = new DesktopNotificationStore(makeOpts())
      this.couponsScreenStore = new CouponsScreenStore(makeOpts())
      this.profileEditorStore = new ProfileEditorStore(makeOpts())

      this.billingScreenStore = new BillingScreenStore(makeOpts())
      this.gettingStartedStore = new GettingStartedStore(makeOpts())
      this.changeEmailDialogStore = new ChangeEmailDialogStore(makeOpts())
      this.notificationPreferencesStore = new NotificationPreferencesStore(
        makeOpts()
      )
      this.mediaSettingsStore = new MediaSettingsStore(localStorage)
      this.mediaSettingsDialogViewModel = new MediaSettingsDialogViewModel(
        this.mediaSettingsStore,
        this.sessionStore
      )
      this.callRootViewModel = new CallRootViewModel(this)
      this.trackingStore = new TrackingStore(makeOpts())
      this.legendSettingsStore = new LegendSettingsStore(makeOpts())
      this.signupScreenStore = new SignupScreenStore(makeOpts())

      this.deleteMessageConfirm = new ConfirmDialogState()

      this.projectRoomViewModel = new ProjectRoomViewModel({
        flashMessageStore: this.flashMessageStore,
        sessionStore: this.sessionStore,
        projectStore: this.projectStore,
        roomStore: this.roomStore,
      })

      this.bookkeepingPlatformConnectionStore =
        new BookkeepingPlatformConnectionStore(makeOpts())
      this.bookkeepingPlatformSelectionStore =
        new BookkeepingPlatformSelectionDialogStore(makeOpts())
      this.bookkeepingPlatformConnectionsStore =
        new BookkeepingPlatformConnectionsDialogStore(makeOpts())
      this.bookkeepingAddManualConnectionStore =
        new BookkeepingAddManualConnectionDialogStore(makeOpts())
      this.bookkeepingOtherConnectionStore =
        new BookkeepingOtherConnectionDialogStore(makeOpts())
      this.bookkeepingStore = new BookkeepingStore(makeOpts())
      this.bookkeepingConfirmAccessStore =
        new BookkeepingConfirmAccessDialogStore(makeOpts())
      this.createBookkeepingPlatformConnectionStore =
        new CreateBookkeepingPlatformConnectionStore(makeOpts())
    })
  }

  @action.bound
  setHydratedWorkspaceDomain(workspaceDomain) {
    this.hydratedWorkspaceDomain = workspaceDomain
  }

  @computed
  get hydratedWorkspaceConfig() {
    return this.hydratedWorkspaceDomain
      ? this.hydratedWorkspaceDomain.workspace.config
      : null
  }

  onTogglesInitialized() {
    this._togglesInitialized.next(true)
  }

  retryOnNetworkFailure(fn) {
    const promise = retryOnNetworkFailure(fn)
    promise.retryMessage = (msg) =>
      promise.firstRetry(() => this.flashMessageStore.create(msg))

    promise.recoveredMessage = (msg) =>
      promise.recovered((message) => message.done(msg).show().autoDismiss())

    promise.networkIssueMessage = (msg) =>
      promise.doneRetrying((_, message) => {
        message.failed(msg).show().autoDismiss()
        return false
      })

    return promise
      .retryMessage('Having network issues, trying again...')
      .recoveredMessage('Connectivity appears to have been restored.')
      .networkIssueMessage(
        'Unable to connect to the server, are you connected to the Internet?'
      )
  }
}

export default new RootStore()
