import { action, computed, observable, reaction } from 'mobx'
import once from 'lodash/once'
import { task } from 'mobx-task'
import { ViewModelList } from '../../../../../../infra/ViewModelList'

/**
 * View model for managing the document supporting item input.
 */
export class DocumentSupportingItemInputViewModel {
  /**
   * The underlying item.
   *
   * @type {RequestedSupportingItem}
   */
  @observable item

  /**
   * List of uploading documents.
   *
   * @type {UploadingDocumentViewModel[]}
   */
  @observable uploading = []

  constructor({
    item,
    documentsAdapter,
    flashMessageStore,
    showingInfoGatheringStage,
  }) {
    /**
     * @type {RequestedSupportingItem}
     */
    this.item = item

    /**
     * @type {DocumentsAdapter}
     */
    this.documentsAdapter = documentsAdapter

    this.flashMessageStore = flashMessageStore

    this.providedDocumentVMs = new ViewModelList({
      models: () => item.input.documents,
      create: (document) =>
        new ProvidedDocumentViewModel({
          item,
          document,
          documentsAdapter,
          flashMessageStore,
          showingInfoGatheringStage,
        }),
    })

    // We're using this to manage the activation and deactivation
    // of the "uploading" view models.
    this.uploadingVMs = new ViewModelList({
      models: () => this.uploading.slice(),
      create: (vm) => vm,
      activate: (vm) => vm.activate(),
      deactivate: (vm) => vm.deactivate(),
    })

    // We will only want to fetch the initial documents once.
    this.fetchProvidedDocuments = this.fetchProvidedDocuments.wrap(once)
  }

  /**
   * Gets the provided documents.
   *
   * @returns {ProvidedDocumentViewModel[]}
   */
  @computed
  get providedDocuments() {
    // Filter out any documents that are currently being provided.
    // It's possible for a realtime event to add the document to the list even before
    // it finishes uploading due to the fact that the document is created on the backend before the file
    // is provided.
    return this.providedDocumentVMs.items.filter((x) => {
      const isUploading =
        !x.document.latestVersion ||
        this.uploading.some((u) => u.document?.id === x.document.id)
      return !isUploading
    })
  }

  /**
   * Whether the input is currently busy with an ongoing operation.
   */
  @computed
  get busy() {
    return this.providedDocuments.some((x) => x.busy)
  }

  /**
   * Whether the document can be linked to this item.
   *
   * @param document
   */
  isDocumentLinkable(document) {
    // Allow linking untyped documents or those that have the correct type.
    // The adapter will assign it a type when linking to the
    // supporting item if it does not have one.
    const canLinkBasedOnDocumentType =
      !document.documentType ||
      document.documentType.shortId === this.item.input.documentTypeShortId

    if (!canLinkBasedOnDocumentType) {
      return false
    }

    return this.providedDocuments.some((p) => p.document === document) === false
  }

  /**
   * Activates the view model.
   */
  activate() {
    this.providedDocumentVMs.activate()
    this.uploadingVMs.activate()

    // If the item says it has document IDs but the in-memory list
    // of the actual document models is not of the same length, then it's
    // possible that we received a real-time event of an update to the checklist
    // but not for the document itself being uploaded, likely due to being
    // disconnected from the Docs API real-time feed.
    // When that happens, we can trigger a fetch of the documents.
    this.disposeDocumentFetchReaction = reaction(
      () => this.item.input.documentIds.slice(),
      (ids) => {
        const input = this.item.input
        if (ids.length !== input.documents.length) {
          this.documentsAdapter.fetchDocuments(ids).then()
        }
      }
    )
  }

  /**
   * Deactivates the view model.
   */
  deactivate() {
    this.disposeDocumentFetchReaction?.()
    this.uploadingVMs.deactivate()
    this.providedDocumentVMs.deactivate()
  }

  /**
   * Fetches the underlying documents for what has been provided.
   */
  @task
  async fetchProvidedDocuments() {
    await this.documentsAdapter.fetchDocuments(this.item.input.documentIds)
  }

  /**
   * Links the document.
   *
   * @param document
   */
  @task.resolved
  async linkDocument(document) {
    await this.documentsAdapter.linkDocument(this.item, document)
  }

  /**
   * Upload a bunch of documents.
   *
   * @param files
   * @returns {Promise<void>}
   */
  async uploadDocuments(files) {
    await Promise.all(files.map(this.uploadDocument.bind(this)))
  }

  /**
   * Uploads a document.
   *
   * @param {File} file
   * @returns {Promise<void>}
   */
  async uploadDocument(file) {
    const vm = this.addUploadingViewModel(file.name)
    try {
      const request = this.documentsAdapter.provideDocument(this.item, file)
      const doc = await request.created
      vm.setDocument(doc)
      await request.provided
    } catch (err) {
      this.flashMessageStore.showForError(err)
    } finally {
      this.removeUploadingViewModel(vm)
    }
  }

  /**
   * Adds a view model for an uploading document with the given title.
   *
   * @param title
   */
  @action.bound
  addUploadingViewModel(title) {
    const vm = new UploadingDocumentViewModel({ title })
    this.uploading.push(vm)
    return vm
  }

  /**
   * Removes the given uploading VM.
   *
   * @param vm
   */
  @action.bound
  removeUploadingViewModel(vm) {
    this.uploading.remove(vm)
  }
}

/**
 * View model for a single provided document.
 */
export class ProvidedDocumentViewModel {
  constructor({ item, document, documentsAdapter, flashMessageStore }) {
    this.item = item
    this.document = document
    this.documentsAdapter = documentsAdapter
    this.flashMessageStore = flashMessageStore

    this.remove = this.remove.bind(this)
  }

  /**
   * Whether this VM is busy.
   *
   * @returns {boolean}
   */
  @computed
  get busy() {
    return this.remove.pending
  }

  /**
   * Removes the provided document.
   */
  @task.resolved
  async remove() {
    try {
      await this.documentsAdapter.removeProvidedDocument(
        this.item,
        this.document
      )
    } catch (err) {
      console.error(err)
      this.flashMessageStore.showForError(err)
    }
  }
}

/**
 * View model tracking a document that is being uploaded.
 */
export class UploadingDocumentViewModel {
  /**
   * Tracks the uploaded percent.
   *
   * @type {number}
   */
  @observable uploadedPercent = 0

  /**
   * The document being uploaded.
   */
  @observable document = null

  constructor({ title }) {
    this.title = title
  }

  /**
   * Activates the view model.
   */
  activate() {
    // When the document has been uploaded, the progress
    // is reset, and the UI updates. However, we still show the uploading UI
    // while the document is being provided to the supporting item, so
    // to prevent the percentage to go back to 0%, we'll copy it and ensure we
    // don't set it back.
    this.disposeReaction = reaction(
      () => this.document?.uploadProgress?.percent,
      (percent) => {
        if (percent) {
          this.uploadedPercent = percent
        }
      },
      { fireImmediately: true }
    )
  }

  /**
   * Deactivates the view model.
   */
  deactivate() {
    this.disposeReaction?.()
  }

  /**
   * Sets the document so we can start tracking the upload percent.
   */
  @action.bound
  setDocument(document) {
    this.document = document
  }
}
