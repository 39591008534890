/**
 * Validates an object that has a `validateAll` and a `validation` context.
 *
 * @param {*} message
 */
export function validateObject(message = 'This field is invalid.') {
  return function validateObjectValidator({ value }) {
    if (value && value.validation) {
      return value.validation.reset().validate().isValid || message
    }

    return true
  }
}

/**
 * Validates an object that has a `validateAll` and a `validation` context.
 *
 * @param {*} message
 */
export function validateSsn(message = 'Please use a valid SSN or ITIN') {
  return function validateSsnValidator({ value }) {
    const ssnRegex = /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/
    const itinRegex = /^9\d{2}-\d{2}-(?!0000)\d{4}$/
    return (
      ssnRegex.test(value) ||
      itinRegex.test(value) ||
      'Please use a valid SSN or ITIN'
    )
  }
}
