import { action, observable, computed } from 'mobx'
import { validationContext, required } from 'validx'

export class PhysicalAddressFormViewModel {
  @observable addressLine1
  @observable addressLine2
  @observable city
  @observable region
  @observable postalCode
  @observable country

  validation = validationContext(this, {
    addressLine1: [required('Address is required')],
    city: [required('City is required')],
    region: [required('Region is required')],
    postalCode: [required('Zip or Postal code is required')],
    country: [required('Country is required')],
  })

  @computed
  get addressDto() {
    return {
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      region: this.region,
      postalCode: this.postalCode,
      country: this.country,
    }
  }

  @action.bound
  parse(contactInfo) {
    if (!contactInfo) {
      return
    }

    this.addressLine1 = contactInfo.addressLine1
    this.addressLine2 = contactInfo.addressLine2
    this.city = contactInfo.city
    this.region = contactInfo.region
    this.postalCode = contactInfo.postalCode
    this.country = contactInfo.country
  }
}
