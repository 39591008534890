import 'sass/base.sass'
import React, { Suspense, lazy } from 'react'
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import store from 'store'
import makeAuthenticate from './interceptors/authenticate'
import makeTrackReferrerMeta from './interceptors/trackReferrerMeta'
import makeRedirectToAuthorization from './interceptors/authRedirect'
import makeCompleteQuickBooksOauth2Flow from './interceptors/oauth2/completeQuickBooksOAuth2Flow'
import makeCompleteXeroOauth2Flow from './interceptors/oauth2/completeXeroOAuth2Flow'
import ErrorBoundry from 'components/ErrorBoundry'

import App from '../App'
import Login from 'screens/Login'
import Signup from 'screens/Signup'
import CompleteRegistration from 'screens/CompleteRegistration'
import CompleteOAuth2Flow from '../../screens/CompleteOAuth2Flow'
import EmailConfirmed from '../../screens/EmailConfirmation/EmailConfirmed/EmailConfirmed'
import ConfirmEmail from '../../screens/EmailConfirmation/ConfirmEmail/ConfirmEmail'
import { ConfirmEmailViewModel } from '../../screens/EmailConfirmation/ConfirmEmail/ConfirmEmailViewModel'
import { getFeatureToggleClient } from 'misc/featureToggles'

const Projects = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Projects')
)
const ProjectDetails = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/ProjectDetails')
)
const Diy = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Diy')
)
const ProjectRoom = lazy(() =>
  import(/* webpackChunkName: "projectRoom" */ 'screens/ProjectRoom')
)
const ProjectWizard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/ProjectWizard')
)
const ReferAFriend = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/ReferAFriend')
)
const Coupons = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Coupons')
)
const Documents = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Documents')
)
const Profile = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Profile')
)
const Billing = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Billing')
)
const Teams = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/Teams')
)
const NotificationPreferences = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'screens/NotificationPreferences')
)
const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: "forgotPassword" */ 'screens/ForgotPassword')
)
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "resetPassword" */ 'screens/ResetPassword')
)
const InvitationRSVP = lazy(() =>
  import(
    /* webpackChunkName: "invitation" */ '@taxfyle/web-commons/lib/iam/containers/InvitationRSVP'
  )
)

if (process.env.NODE_ENV === 'development') {
  window.rootStore = store
}

const authenticate = makeAuthenticate({ authStore: store.authStore })
const redirectToAuthorization = makeRedirectToAuthorization({
  authStore: store.authStore,
})
const trackReferrerMeta = makeTrackReferrerMeta({ authStore: store.authStore })

const completeQuickBooksOauth2Flow = makeCompleteQuickBooksOauth2Flow({
  store,
})

const completeXeroOauth2Flow = makeCompleteXeroOauth2Flow({ store })

const useEmailConfirmationFlow = getFeatureToggleClient().variation(
  'UseEmailConfirmationFlow',
  false
)

const createElement = (Component, props) => (
  <ErrorBoundry>
    <Component {...props} rootStore={store} />
  </ErrorBoundry>
)

const onRouterUpdate = (location) => {
  store.trackingStore.page(location.pathname)
}

const AppRouter = () => (
  <Suspense fallback={<React.Fragment />}>
    <Router
      history={browserHistory}
      createElement={createElement}
      onUpdate={onRouterUpdate(location)}
    >
      <Route
        path="/__session/:session_payload"
        onEnter={(_, replace) => replace({ pathname: '/' })}
      />
      <Route path="/authorize" onEnter={redirectToAuthorization} />
      <Route onEnter={trackReferrerMeta}>
        {useEmailConfirmationFlow && (
          <Route
            path="/confirm-email"
            component={() => (
              <ConfirmEmail
                vm={new ConfirmEmailViewModel(store.flashMessageStore)}
              />
            )}
          />
        )}
        {useEmailConfirmationFlow && (
          <Route path="/email-confirmed" component={EmailConfirmed} />
        )}
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route
          path="/complete-registration/:token"
          component={CompleteRegistration}
        />
        <Route
          path="invitation/:token"
          component={InvitationRSVP}
          onEnter={authenticate}
        />
        <Route path="/" component={App} onEnter={authenticate}>
          <IndexRoute component={Projects} />
          <Route
            path="quick-books-oauth2"
            onEnter={completeQuickBooksOauth2Flow}
            component={CompleteOAuth2Flow}
          />
          <Route
            path="xero-oauth2"
            onEnter={completeXeroOauth2Flow}
            component={CompleteOAuth2Flow}
          />
          <Route
            path="notifications/preferences"
            component={NotificationPreferences}
          />

          <Route path="documents" component={Documents} />
          <Route path="coupons" component={Coupons} />
          <Route path="billing" component={Billing} />
          <Route path="profile" component={Profile} />
          <Route path="refer" component={ReferAFriend} />
          <Route path="new" component={ProjectWizard} />
          <Route path="new/:serviceType" component={ProjectWizard} />
          <Route path="teams" component={Teams} />
          <Route path="project/:id" component={ProjectDetails} />
          <Route path="diy" component={() => <Diy rootStore={store} />} />
          <Route path="project/:id/rooms/:roomId" component={ProjectRoom} />
          <Route path="project/:id/edit" component={ProjectWizard} />
        </Route>
      </Route>
    </Router>
  </Suspense>
)

export default AppRouter
