import { action, computed, observable } from 'mobx'
import { task } from 'mobx-task'
import { getFeatureToggleClient } from 'misc/featureToggles'
import { ViewModelList } from '../../../infra/ViewModelList'
import { RequestedSupportingItemViewModel } from './RequestedSupportingItemViewModel'
import { SectionViewModel } from './SectionViewModel'
import { DocumentsAdapter } from './DocumentsAdapter'

/**
 * View model for info gathering.
 */
export class InfoGatheringState {
  /**
   * Whether we are currently showing the info gathering stage.
   * @type {boolean}
   */
  @observable showingInfoGatheringStage = false

  /**
   * The checklist model.
   * @type {import("domain/info-gathering/Checklist").Checklist | null}
   */
  @observable checklist = null

  /**
   * The job.
   */
  @observable job = null

  /**
   * The actively selected item.
   *
   * @type {RequestedSupportingItemViewModel | null}
   */
  @observable selectedItem = null

  /**
   * Whether the sidebar is showing.
   * When `null`, the sidebar is not shown, and has not been toggled yet,
   * which is used to automatically show the sidebar when appropriate.
   * @type {null | boolean}
   */
  @observable showingSidebar = null

  /**
   * Whether the final "Info Gathering Completed" dialog is showing.
   *
   * @type {boolean}
   */
  @observable showingCompletedDialog = false

  /**
   * Whether we are showing the confirmation dialog to complete info
   * gathering early.
   *
   * @type {boolean}
   */
  @observable showingConfirmDialog = false

  /**
   * Whether the privacy security dialog is showing.
   */
  @observable showingPrivacyDialog = false

  /**
   * Constructor.
   */
  constructor({
    jobStore,
    infoGatheringStore,
    individualProfileStore,
    businessProfileStore,
    documentStore,
    documentTypeStore,
    documentVersionStore,
    documentAccessStore,
    flashMessageStore,
    sessionStore,
    projectDetailsStore,
    jobTaxAssistantGreetingDialogStore,
  }) {
    this.completeInfoGathering = this.completeInfoGathering.bind(this)

    const documentsAdapter = new DocumentsAdapter({
      documentStore,
      documentTypeStore,
      documentVersionStore,
      documentAccessStore,
      infoGatheringStore,
      getCurrentJob: () => this.job,
    })

    this.jobStore = jobStore
    this.projectDetailsStore = projectDetailsStore
    this.documentStore = documentStore
    this.infoGatheringStore = infoGatheringStore
    this.flashMessageStore = flashMessageStore
    this.sessionStore = sessionStore
    this.jobTaxAssistantGreetingDialogStore = jobTaxAssistantGreetingDialogStore

    this.itemViewModels = new ViewModelList({
      models: () => this.checklist?.allItems ?? [],
      activate: (vm) => vm.activate(),
      deactivate: (vm) => vm.deactivate(),
      create: (item) =>
        new RequestedSupportingItemViewModel({
          item,
          documentsAdapter,
          flashMessageStore,
          individualProfileStore,
          businessProfileStore,
          infoGatheringStore,
          showingInfoGatheringStage: () => this.showingInfoGatheringStage,
          isSelected: (self) => this.selectedItem === self,
          selectItem: this.selectItem,
          getCurrentJob: () => this.job,
        }),
    })

    this.sectionViewModels = new ViewModelList({
      models: () => this.checklist?.sections ?? [],
      activate: (vm) => vm.activate(),
      deactivate: (vm) => vm.deactivate(),
      create: (section) =>
        new SectionViewModel({
          section,
          getItemViewModel: (item) => this.itemViewModels.getByModel(item),
        }),
    })
  }

  /**
   * Gets the section view models.
   */
  @computed
  get sections() {
    return this.sectionViewModels.items
  }

  /**
   * The selected item's index in the overall list.
   */
  @computed
  get selectedItemIndex() {
    return this.itemViewModels.items.indexOf(this.selectedItem)
  }

  /**
   * Whether the last item is selected.
   *
   * @returns {boolean}
   */
  @computed
  get isLastItemSelected() {
    if (this.itemViewModels.items.length > 0) {
      const lastItem =
        this.itemViewModels.items[this.itemViewModels.items.length - 1]

      return this.selectedItem === lastItem
    }

    return false
  }

  /**
   * Whether all required items have been provided.
   */
  @computed
  get requiredItemsProvided() {
    return this.itemViewModels.items
      .filter((vm) => vm.item.required)
      .every((vm) => vm.item.completed)
  }

  /**
   * Whether any items have been provided.
   */
  @computed
  get itemsProvided() {
    return this.itemViewModels.items.some((vm) => vm.item.completed)
  }

  /**
   * Whether the Next button is enabled, including the complete info gathering action.
   *
   * @returns {boolean}
   */
  @computed
  get nextEnabled() {
    if (this.completeInfoGathering.pending) {
      return false
    }

    if (this.selectedItem?.item.required && !this.selectedItem.item.completed) {
      return false
    }

    // If the selected item is the last item, and we have not
    // completed all required items, then disable it.
    if (this.isLastItemSelected) {
      return this.requiredItemsProvided
    }

    return true
  }

  /**
   * The next item based on the current selection.
   */
  @computed
  get nextItem() {
    const nextIndex = this.selectedItemIndex + 1
    if (nextIndex === -1 || nextIndex >= this.itemViewModels.items.length) {
      return null
    }

    return this.itemViewModels.items[nextIndex]
  }

  /**
   * Gets the current user.
   */
  @computed
  get currentUser() {
    return this.sessionStore.user
  }

  /**
   * Gets whether the toggle is enabled.
   *
   * @returns {boolean}
   */
  get isToggleEnabled() {
    return getFeatureToggleClient().variation('CustomerPortal.InfoGathering')
  }

  /**
   * Gets whether the job contains notes supporting item and the toggle is enabled.
   *
   * @returns {boolean}
   */
  @computed
  get areNotesEnabled() {
    return (
      getFeatureToggleClient().variation('Portals.NotesSupporting', false) &&
      this.checklist.notesSection
    )
  }

  @computed
  get disableChatForFirmAdmin() {
    return Boolean(this.areNotesEnabled && this.projectDetailsStore.isFirmAdmin)
  }

  /**
   * Whether the job has a checklist.
   */
  @computed
  get hasChecklist() {
    if (!this.checklist) {
      return false
    }

    return !this.checklist.isEmpty
  }

  /**
   * Whether the job tax assistant is enabled.
   */
  @computed
  get isJobTaxAssistantEnabled() {
    return this.projectDetailsStore.isJobTaxAssistantEnabled
  }

  /**
   * Whether the job is in info gathering step.
   */
  @computed
  get inInfoGathering() {
    return this.job.status === 'INFO_GATHERING'
  }

  /**
   * Activate the view model.
   *
   * @returns {Promise<void>}
   */
  @task
  async activate(job) {
    this.sectionViewModels.activate()
    this.itemViewModels.activate()
    this.setJob(job)

    // If the toggle is not enabled, don't show the info gathering stage.
    if (!this.isToggleEnabled) {
      this.hideInfoGatheringStage()

      // Display the job tax assistant greeting if we're not in info gathering phase.
      if (this.isJobTaxAssistantEnabled) {
        this.jobTaxAssistantGreetingDialogStore.open(this.job.id)
      }

      return
    }

    try {
      // Retrieve the checklist.
      const checklist = await this.infoGatheringStore.fetchChecklist(job.id)
      this.setChecklist(checklist)
      // If the checklist is not empty and we are in the info gathering stage:
      // Job assistant enabled and no items have been completed: show the view.
      // Job assistant disabled: show the view.
      if (
        this.inInfoGathering &&
        !this.checklist.isEmpty &&
        (!this.isJobTaxAssistantEnabled ||
          (this.isJobTaxAssistantEnabled && !this.itemsProvided))
      ) {
        this.showInfoGatheringStage()
      } else if (this.isJobTaxAssistantEnabled) {
        this.jobTaxAssistantGreetingDialogStore.open(this.job.id)
      }
    } catch (err) {
      // Ignore the error; we will be displaying job details instead
      // as a fallback.
      console.error('Unable to retrieve checklist', err)
      this.hideInfoGatheringStage()

      // Display the job tax assistant greeting if we're not in info gathering phase.
      if (this.isJobTaxAssistantEnabled) {
        this.jobTaxAssistantGreetingDialogStore.open(this.job.id)
      }
    }
  }

  /**
   * Selects an item to view. Can be set to `null` to deselect.
   */
  @action.bound
  selectItem(item) {
    // If the sidebar has not been toggled yet, toggle it now.
    if (this.showingSidebar === null) {
      this.toggleSidebar()
    }

    this.selectedItem = item ?? null
  }

  /**
   * Deselects the currently selected item.
   */
  @action.bound
  deselectItem() {
    this.selectItem(null)
  }

  /**
   * Toggles the sidebar visibility.
   */
  @action.bound
  toggleSidebar() {
    this.showingSidebar = !this.showingSidebar
  }

  /**
   * Hides the sidebar.
   */
  @action.bound
  hideSidebar() {
    this.showingSidebar = false
  }

  /**
   * Proceed to the next item.
   */
  @action.bound
  selectNextItem() {
    if (this.nextItem) {
      this.selectItem(this.nextItem)
    }
  }

  @action.bound
  bypassInfoGatheringStage() {
    this.goToJob()
    this.jobTaxAssistantGreetingDialogStore.open(this.job.id)
  }

  /**
   * Sets the job.
   *
   * @param job
   */
  @action.bound
  setJob(job) {
    this.job = job
  }

  /**
   * Sets the checklist.
   *
   * @param checklist
   */
  @action.bound
  setChecklist(checklist) {
    this.checklist = checklist
  }

  /**
   * Shows the confirm dialog to ask the user if they are certain they
   * wish to continue despite not having filled in everything.
   */
  @action.bound
  tryCompleteInfoGatheringEarly() {
    const allItemsCompleted = this.itemViewModels.items.every(
      (vm) => vm.item.completed
    )
    if (allItemsCompleted) {
      // All items are completed, no reason to ask for confirmation.
      return this.completeInfoGathering()
    }

    this.showingConfirmDialog = true
  }

  /**
   * Closes the confirm dialog.
   */
  @action.bound
  closeConfirmDialog() {
    this.showingConfirmDialog = false
  }

  /**
   * Show the info gathering stage.
   */
  @action.bound
  showInfoGatheringStage() {
    this.showingInfoGatheringStage = true

    // If any item has been provided, show the next pending item, or the last
    // item if none are pending.
    const itemVms = this.itemViewModels.items
    if (this.itemsProvided) {
      const nextItem = itemVms.find((x) => !x.item.completed)
      this.selectItem(nextItem ?? itemVms[itemVms.length - 1])
    }
  }

  /**
   * Hide the info gathering stage.
   */
  @action.bound
  hideInfoGatheringStage() {
    this.showingInfoGatheringStage = false
  }

  /**
   * Reset the state so it's ready for a new activation.
   */
  @action.bound
  reset() {
    this.activate.reset()

    // Ensure we don't subscribe more than once.
    // The parent of this structure does not have a deactivation
    // hook so we just deactivate here to ensure we at least don't subscribe
    // more than at most once.
    this.sectionViewModels.deactivate()
    this.itemViewModels.deactivate()

    // Deselect everything
    this.selectItem(null)
    this.setChecklist(null)
    this.showingSidebar = null
    this.showingCompletedDialog = false
    this.hideInfoGatheringStage()
  }

  /**
   * Complete the info gathering stage.
   * @returns {Promise<void>}
   */
  @task.resolved
  async completeInfoGathering() {
    try {
      // When the job tax assistant is enabled, we're already in the job details page.
      // No need to redirect or close any modals.
      if (!this.isJobTaxAssistantEnabled) {
        // If the job has been updated in the background (through a realtime event
        // or otherwise) and we are no longer in the info gathering stage,
        // we can skip the complete call and go straight to the job.
        if (!this.inInfoGathering) {
          this.goToJob()
          this.closeConfirmDialog()
          return
        }
      }

      await this.jobStore.completeInfoGathering(this.job.id)

      // Same as above being in the job details page already.
      if (!this.isJobTaxAssistantEnabled) {
        this.closeConfirmDialog()
        this.showCompletedDialog()
      }
    } catch (err) {
      console.error('Completing info gathering failed', err)
      this.flashMessageStore.showForError(err)
    }
  }

  /**
   * Shows the "Info Gathering Completed" dialog.
   */
  @action.bound
  showCompletedDialog() {
    this.showingCompletedDialog = true
  }

  /**
   * Dismisses the info gathering screen to reveal the job details.
   */
  @action.bound
  goToJob() {
    this.deselectItem()
    this.showingCompletedDialog = false
    this.hideInfoGatheringStage()
  }

  /**
   * Shows the privacy dialog.
   */
  @action.bound
  showPrivacyDialog() {
    this.showingPrivacyDialog = true
  }

  /**
   * Closes the privacy dialog.
   */
  @action.bound
  closePrivacyDialog() {
    this.showingPrivacyDialog = false
  }
}
