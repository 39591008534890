import { observable } from 'mobx'
import { Model } from 'libx'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export default class SpousalProfileVersion extends Model {
  @observable
  id = ''

  @observable
  version = 0

  @observable
  workspaceId = ''

  @observable
  ownerUserId = ''

  @observable
  personalInfo = null

  @observable
  occupation = ''

  @observable
  createTime = new Timestamp()

  @observable
  updateTime = new Timestamp()

  parse(json) {
    return {
      id: json.id,
      version: json.version,
      collectionId: json.collectionId,
      workspaceId: json.workspaceId,
      ownerUserId: json.ownerUserId,
      personalInfo: this.toPersonalInfoDTO(json.personalInfo),
      occupation: json.occupation,
      createTime: Timestamp.fromDate(new Date(json.createTime)),
      updateTime: Timestamp.fromDate(new Date(json.updateTime)),
    }
  }

  toPersonalInfoDTO(personalInfo) {
    if (!personalInfo) {
      return personalInfo
    }
    return {
      firstName: personalInfo.firstName,
      middleInitial: personalInfo.middleInitial,
      lastName: personalInfo.lastName,
      dateOfBirth: personalInfo.dateOfBirth,
      personalTaxpayerIdentificationNumber:
        personalInfo.personalTaxpayerIdentificationNumber,
    }
  }
}
