import { action, runInAction, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from '@taxfyle/web-commons/lib/utils/errorUtil'
import { browserHistory } from 'react-router'
import links from '../../misc/links'
import QS from 'qs'

/**
 * Responsible for managing the current embedded room view model.
 */
export default class ProjectRoomViewModel {
  @observable
  job

  @observable
  room

  @observable
  roomUrl

  @observable
  qs

  @observable
  columnTaxUrl = null

  @observable
  diyHidden = true

  @observable
  showShareDiyInstructions = false

  constructor({ flashMessageStore, sessionStore, projectStore, roomStore }) {
    this.flashMessageStore = flashMessageStore
    this.sessionStore = sessionStore
    this.projectStore = projectStore
    this.roomStore = roomStore
  }

  @computed
  get workspace() {
    return this.sessionStore.workspace
  }

  @computed
  get member() {
    return this.sessionStore.member
  }

  @computed
  get hideNavbar() {
    return this.qs?.hideNavbar === 'true'
  }

  @computed
  get headerProps() {
    const { simplifiedNavBar } =
      this.sessionStore.workspace.features.jobDetailsConfig
    return (
      simplifiedNavBar.enabled && {
        simplified: true,
        simplifiedLogo: simplifiedNavBar.image,
        backTargetUrl: simplifiedNavBar.targetUrl,
      }
    )
  }

  @computed
  get composedRoomUrl() {
    return `${this.roomUrl}&displayName=${this.member.displayName}&logo=off`
  }

  @action.bound
  hideShareDiyInstructions() {
    this.showShareDiyInstructions = false
  }

  @action.bound
  redirectBack() {
    if (this.job || this.columnTaxUrl) {
      browserHistory.go(-1)
      return
    }
    browserHistory.push(links.projects())
  }

  @action.bound
  showDiy() {
    this.diyHidden = false
  }

  @action.bound
  hideDiy() {
    this.diyHidden = true
  }

  activate = task(async (jobId, roomId, queryParams) => {
    try {
      const [job, room] = await Promise.all([
        this.projectStore.fetchProject(jobId),
        this.roomStore.fetchRoom(roomId),
      ])

      runInAction(() => {
        this.qs = QS.parse(queryParams, { ignoreQueryPrefix: true })
        this.job = job
        this.room = room
      })

      await this.loadDiy(job)

      if (this.room.status !== 'Active') {
        this.redirectBack()
        return
      }

      const roomDescriptor = await this.roomStore.joinRoom(roomId)
      switch (roomDescriptor.type) {
        case 'Whereby':
          runInAction(() => {
            this.roomUrl = new URL(roomDescriptor.roomUrl)
          })
          return
        default:
          this.redirectBack()
      }
    } catch (err) {
      this.flashMessageStore.create(extractMessageFromError(err)).failed()
      this.redirectBack()
    }
  })

  @task
  async loadDiy(job) {
    const diyJobId = job.jobType?.consultation?.diyJobId?.value
    if (!diyJobId) {
      runInAction(() => {
        this.columnTaxUrl = null
        this.showShareDiyInstructions = false
      })
      return
    }

    const taxYearCheck =
      await this.projectStore.rootStore.api.diy.checkTaxYearForDiyJob({
        diyJobId: diyJobId,
      })

    if (taxYearCheck.currentTaxYear !== taxYearCheck.diyJobTaxYear) {
      runInAction(() => {
        this.columnTaxUrl = null
        this.showShareDiyInstructions = false
      })
      return
    }

    const diyUrl = await this.projectStore.rootStore.api.diy.createDiyUrl({
      workspaceId: job.workspaceId,
    })

    runInAction(() => {
      this.columnTaxUrl = diyUrl.url
      this.showShareDiyInstructions = true
    })
  }
}
