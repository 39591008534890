import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required, func } from 'validx'
import { validateObject } from 'utils/validx-validators'
import { extractMessageFromError } from 'utils/errorUtil'
import moment from 'moment'
import { PhysicalAddressFormViewModel } from '../../PhysicalAddressForm/PhysicalAddressFormViewModel'

export class BusinessInfoSupportingItemInputViewModel {
  @observable
  item

  @observable
  showingDialog = false

  @observable
  businessName

  @observable
  dateOfIncorporation

  @observable
  employerIdentificationNumber

  @observable
  physicalAddressVM = new PhysicalAddressFormViewModel()

  validation = validationContext(this, {
    businessName: [required('Business name is required')],
    dateOfIncorporation: [required('Date of incorporation is required')],
    employerIdentificationNumber: [
      required('EIN is required'),
      func(({ value }) => {
        const regex = /^(?!00)\d{2}-(?!0000000)\d{7}$/
        return regex.test(value) || 'Please use a valid EIN'
      }),
    ],
    physicalAddressVM: [validateObject('physicalAddressVM')],
  })

  constructor({
    item,
    flashMessageStore,
    businessProfileStore,
    infoGatheringStore,
    getCurrentJob,
  }) {
    this.item = item
    this.flashMessageStore = flashMessageStore
    this.businessProfileStore = businessProfileStore
    this.infoGatheringStore = infoGatheringStore
    this.getCurrentJob = getCurrentJob
  }

  @computed
  get currentJob() {
    return this.getCurrentJob()
  }

  @computed
  get profile() {
    return this.businessProfileStore.getBusinessProfile(
      this.item.input.profileId,
      this.item.input.profileVersion
    )
  }

  @computed
  get contactInfoLines() {
    return [
      this.profile.contactInfo?.addressLine1,
      this.profile.contactInfo?.addressLine2,
      this.cityStateZip,
      this.profile.contactInfo?.country,
    ].filter((line) => Boolean(line))
  }

  @computed
  get employerIdentificationNumberDisplay() {
    return this.profile.businessInfo.employerIdentificationNumber.replace(
      /\d{2}-.*(\d{3})$/,
      '✶✶-✶✶✶✶$1'
    )
  }

  @computed
  get headerDescription() {
    return [
      this.profile.businessInfo.dateOfIncorporation,
      this.employerIdentificationNumberDisplay,
    ]
      .filter((line) => Boolean(line))
      .join(' / ')
  }

  /**
   * The summary of the city, state, and zip.
   */
  @computed
  get cityStateZip() {
    return [
      this.profile.contactInfo.city,
      this.profile.contactInfo.region,
      this.profile.contactInfo.postalCode,
    ]
      .filter((line) => Boolean(line))
      .join(', ')
  }

  activate = task.resolved(async () => {
    await this.fetchBusinessProfile()
  })

  @task
  async fetchBusinessProfile() {
    if (!this.item.input?.profileId) {
      return
    }

    await this.businessProfileStore
      .fetchBusinessProfileForJob(this.currentJob.id)
      .then(this.hydrate)
      .catch((e) => {
        this.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })
  }

  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    let profile

    try {
      profile = this.profile
        ? await this.updateBusinessProfile()
        : await this.createBusinessProfile()

      await this.infoGatheringStore.provideBusinessInfo(
        profile.id,
        profile.version,
        this.currentJob.id,
        this.item
      )

      const message = this.profile
        ? 'Business profile successfully changed.'
        : 'Business profile successfully added.'

      this.flashMessageStore.create({ message }).done().autoDismiss(4000)

      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }

  @action.bound
  async createBusinessProfile() {
    return this.businessProfileStore.createBusinessProfile(
      this.currentJob.workspaceId,
      {
        businessName: this.businessName,
        dateOfIncorporation: moment(this.dateOfIncorporation).format(
          'MM/DD/yyyy'
        ),
        employerIdentificationNumber: this.employerIdentificationNumber,
      },
      {
        contactAddress: this.physicalAddressVM.addressDto,
      }
    )
  }

  @action.bound
  async updateBusinessProfile() {
    return this.businessProfileStore.updateBusinessProfile(
      this.profile.id,
      {
        businessName: this.businessName,
        dateOfIncorporation: moment(this.dateOfIncorporation).format(
          'MM/DD/yyyy'
        ),
        employerIdentificationNumber: this.employerIdentificationNumber,
      },
      {
        contactAddress: this.physicalAddressVM.addressDto,
      }
    )
  }

  @action
  showDialog() {
    this.showingDialog = true
  }

  @action
  closeDialog() {
    this.showingDialog = false
  }

  @action.bound
  hydrate(profile) {
    this.businessName = profile.businessInfo?.businessName
    this.dateOfIncorporation = moment(
      profile.businessInfo?.dateOfIncorporation
    ).format('yyyy-MM-DD')
    this.employerIdentificationNumber =
      profile.businessInfo?.employerIdentificationNumber

    // Populate the physical address
    this.physicalAddressVM.parse(this.profile.contactInfo)
  }
}
