import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import memoize from 'memoizee'
import { computed, runInAction, when } from 'mobx'
import { task } from 'mobx-task'
import links from 'misc/links'
import { browserHistory } from 'react-router'

export default class TaxProjectServiceTypeState {
  stepId = 'TaxProjectServiceType'
  title = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.Header',
    'How would you like to file your taxes?'
  )

  description = T(
    'Web.Onboarding.TaxLegendServiceTypeSelect.Description',
    'Choose the level of support that works best for your situation'
  )

  trackingId = 'Onboarding - Tax Project Service Type Selection'

  constructor({ wizard }) {
    this.wizard = wizard
    this.rootStore = wizard.rootStore
    this.activate = this.activate.wrap((fn) =>
      memoize(fn, { promise: true, length: 0 })
    )
    this.rootStore.sessionStore.onWorkspaceSelected(() => this.activate.clear())
  }

  @computed
  get relevant() {
    return (
      this.wizard.diyEnabled &&
      this.wizard.job === null &&
      this.wizard.getStep('ProjectServiceType').legendServiceType === 'TAX'
    )
  }

  @computed
  get descriptionDiyPrice() {
    if (!this.rootStore.sessionStore.workspace.config.diy?.charge_client) {
      return undefined
    }

    return this.rootStore.sessionStore.workspace.config.diy?.amount_to_charge
  }

  @task
  async activate() {
    await when(() => this.rootStore.sessionStore.workspace)
  }

  goToFullService = task.resolved(async () => {
    runInAction(() => {
      this.wizard.next()
    })
  })

  goToDiy = task.resolved(async () => {
    runInAction(() => {
      browserHistory.push(links.diy(this.wizard.qs?.hideNavbar))
    })
  })
}
