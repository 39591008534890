import { action, computed, observable } from 'mobx'
import {
  DocumentSupportingItemInput,
  PersonalInfoSupportingItemInput,
  SpousalInfoSupportingItemInput,
  BusinessInfoSupportingItemInput,
} from '../../../domain/info-gathering/RequestedSupportingItem'
import { PersonalInfoSupportingItemInputViewModel } from './SelectedItemPage/Inputs/PersonalInfoSupportingItemPageInput/PersonalInfoSupportingItemInputViewModel'
import { SpousalInfoSupportingItemInputViewModel } from './SelectedItemPage/Inputs/SpousalInfoSupportingItemPageInput/SpousalInfoSupportingItemInputViewModel'
import { DocumentSupportingItemInputViewModel } from './SelectedItemPage/Inputs/DocumentSupportingItemPageInput/DocumentSupportingItemInputViewModel'
import { BusinessInfoSupportingItemInputViewModel } from './SelectedItemPage/Inputs/BusinessInfoSupportingItemPageInput/BusinessInfoSupportingItemInputViewModel'

/**
 * View model for a requested supporting item.
 */
export class RequestedSupportingItemViewModel {
  /**
   * The underlying item.
   *
   * @type {import('domain/info-gathering/RequestedSupportingItem').RequestedSupportingItem}
   */
  @observable item

  constructor({
    item,
    isSelected,
    selectItem,
    showingInfoGatheringStage,
    documentsAdapter,
    flashMessageStore,
    individualProfileStore,
    businessProfileStore,
    infoGatheringStore,
    getCurrentJob,
  }) {
    this.item = item
    this._isSelected = isSelected
    this._selectItem = selectItem
    this.showingInfoGatheringStage = showingInfoGatheringStage
    this.documentsAdapter = documentsAdapter
    this.flashMessageStore = flashMessageStore
    this.individualProfileStore = individualProfileStore
    this.businessProfileStore = businessProfileStore
    this.infoGatheringStore = infoGatheringStore
    this.input = this._createInput()
    this.getCurrentJob = getCurrentJob
  }

  /**
   * Whether the item is currently selected.
   */
  @computed
  get selected() {
    return this._isSelected(this)
  }

  /**
   * Whether the VM is performing an operation right now.
   *
   * @returns {boolean}
   */
  @computed
  get busy() {
    return Boolean(this.input?.busy)
  }

  /**
   * Activates the view model.
   */
  activate() {
    this.input?.activate?.()
  }

  /**
   * Deactivates the view model.
   */
  deactivate() {
    this.input?.deactivate?.()
  }

  /**
   * Selects the current item.
   */
  @action.bound
  select() {
    this._selectItem(this)
  }

  /**
   * Creates the input view model based on the input type.
   *
   * @private
   */
  _createInput() {
    if (this.item.input instanceof DocumentSupportingItemInput) {
      return new DocumentSupportingItemInputViewModel({
        item: this.item,
        showingInfoGatheringStage: this.showingInfoGatheringStage,
        documentsAdapter: this.documentsAdapter,
        flashMessageStore: this.flashMessageStore,
      })
    }

    if (this.item.input instanceof PersonalInfoSupportingItemInput) {
      return new PersonalInfoSupportingItemInputViewModel({
        item: this.item,
        individualProfileStore: this.individualProfileStore,
        infoGatheringStore: this.infoGatheringStore,
        flashMessageStore: this.flashMessageStore,
        getCurrentJob: () => this.getCurrentJob(),
      })
    }

    if (this.item.input instanceof SpousalInfoSupportingItemInput) {
      return new SpousalInfoSupportingItemInputViewModel({
        item: this.item,
        individualProfileStore: this.individualProfileStore,
        infoGatheringStore: this.infoGatheringStore,
        flashMessageStore: this.flashMessageStore,

        getCurrentJob: () => this.getCurrentJob(),
      })
    }

    if (this.item.input instanceof BusinessInfoSupportingItemInput) {
      return new BusinessInfoSupportingItemInputViewModel({
        item: this.item,
        showingInfoGatheringStage: this.showingInfoGatheringStage,
        flashMessageStore: this.flashMessageStore,
        businessProfileStore: this.businessProfileStore,
        infoGatheringStore: this.infoGatheringStore,
        getCurrentJob: () => this.getCurrentJob(),
      })
    }

    return null
  }
}
