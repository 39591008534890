import { Store } from 'libx'
import { map, filter } from 'rxjs/operators'
import { StatusCode } from 'grpc-web'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import {
  JobTaxAssistantReplyStatus,
  GetJobTaxAssistantRequest,
} from '@taxfyle/api-internal/internal/job_tax_assistant_pb'
import { maybeParseDate } from 'utils/dateUtil'
import { timestampToISO } from 'utils/grpcUtil'
import { JobTaxAssistant } from '../domain/JobTaxAssistant'

export default class JobTaxAssistantStore extends Store {
  assistants = this.collection({
    model: JobTaxAssistant,
    getModelId: (x) => x.jobId,
    getDataId: (x) => x.job_id,
  })

  events$ = this.rootStore.api.jobTaxAssistant.events$.pipe(
    map((e) => this.mapRealtimeEvent(e)),
    filter((e) => e !== null && e !== undefined)
  )

  constructor() {
    super(...arguments)
    this.events$.subscribe()
  }

  forJob(jobId) {
    return this.assistants.get(jobId)
  }

  getPrompts() {
    return [
      'How do I know if I should itemize deductions or take the standard deduction?',
      'What types of expenses are tax-deductible?',
      'What documents do I need for capital gains or losses?',
      'What documents are needed to claim dependents?',
      'What documents do I need to deduct mortgage interest?',
      'What kind of proof do I need for charitable donations?',
      'What medical expenses are deductible?',
      'What if I have multiple income sources (e.g., freelancing, investments, rental properties)?',
      'What deadlines should I keep in mind for document gathering?',
      'What records should I have for business income and expenses?',
      'How do I prove eligibility for education tax credits?',
      'Am I eligible for the home office deduction if I work remotely?',
      'Are early 401(k) withdrawals subject to penalties? Are there exceptions?',
      'What qualifies as foreign income for the Foreign Tax Credit (FTC)?',
    ]
  }

  async getJobTaxAssistant(jobId) {
    const request = new GetJobTaxAssistantRequest().setJobId(jobId)

    return this.rootStore.api.jobTaxAssistant
      .get(request)
      .then((response) => mapJobtaxAssistantProtoToDTO(response))
      .then((assistant) => this.assistants.set(assistant))
      .catch((e) => {
        if (e.code === StatusCode.NOT_FOUND) {
          console.log('Job tax assistant not enabled for this job', jobId)
          return undefined
        }

        throw e
      })
  }

  mapRealtimeEvent(e) {
    switch (e.getTypeCase()) {
      case RealtimeEvent.TypeCase.JOB_TAX_ASSISTANT_UPDATED:
        return this.mapJobTaxAssistantUpdatedRealtimeEvent(
          e.getJobTaxAssistantUpdated()
        )
    }
  }

  mapJobTaxAssistantUpdatedRealtimeEvent(event) {
    const proto = event?.getAssistant()

    if (!proto) {
      return
    }

    const assistantDTO = mapJobtaxAssistantProtoToDTO(proto)

    const assistant = this.assistants.get(assistantDTO.job_id)
    if (!assistant) {
      return this.assistants.set(assistantDTO)
    }

    const updateTime = maybeParseDate(assistantDTO.update_time)

    if (
      updateTime &&
      assistant.dateModified &&
      assistant.dateModified < updateTime
    ) {
      return assistant.set(assistantDTO, { parse: true, stripUndefined: true })
    }
  }
}

function mapJobtaxAssistantProtoToDTO(proto) {
  return {
    job_id: proto.getJobId(),
    reply_status: mapJobTaxAssistantReplyStatusProto(proto.getReplyStatus()),
    external_reply_id: proto.getExternalReplyId()?.getValue(),
    active: proto.getActive(),
    update_time: timestampToISO(proto.getUpdateTime()),
  }
}

function mapJobTaxAssistantReplyStatusProto(proto) {
  switch (proto) {
    case JobTaxAssistantReplyStatus.JOB_TAX_ASSISTANT_REPLY_STATUS_UNSPECIFIED:
      return 'IDLE'
    case JobTaxAssistantReplyStatus.IDLE:
      return 'IDLE'
    case JobTaxAssistantReplyStatus.PROCESSING:
      return 'PROCESSING'
  }
}
