import { Store } from 'libx'
import BusinessProfileVersion from '../domain/business-profile/BusinessProfileVersion'

export default class BusinessProfileStore extends Store {
  businessProfiles = this.collection({
    model: BusinessProfileVersion,
    getDataId: (data) => `${data.id}:${data.version}`,
    getModelId: (model) => `${model.id}:${model.version}`,
  })

  constructor({ rootStore }) {
    super({ rootStore })

    this.api = this.rootStore.api.businessProfile
  }

  getBusinessProfile(id, version) {
    return this.businessProfiles.get(`${id}:${version}`)
  }

  async fetchBusinessProfile(id) {
    return this.api.getBusinessProfile(id).then((profile) => {
      return this.businessProfiles.set(profile)
    })
  }

  async fetchBusinessProfileForJob(jobId) {
    return this.api.getBusinessProfileForJob(jobId).then((profile) => {
      return this.businessProfiles.set(profile)
    })
  }

  async createBusinessProfile(workspaceId, businessInfo, contactInfo) {
    return this.api
      .createBusinessProfile(workspaceId, businessInfo, contactInfo)
      .then((res) => this.fetchBusinessProfile(res.id))
  }

  async updateBusinessProfile(id, businessInfo, contactInfo) {
    return this.api
      .updateBusinessProfile(id, businessInfo, contactInfo)
      .then((res) => this.fetchBusinessProfile(res.id))
  }
}
