import { ContactInformation } from '@taxfyle/api-internal/shared/types/contact_info_pb'
import { PhysicalAddress } from '@taxfyle/api-internal/shared/types/physical_address_pb'

export function contactInfoToProto(contactInfo) {
  if (!contactInfo) {
    return null
  }

  return new ContactInformation()
    .setPhoneNumber(contactInfo.phoneNumber)
    .setEmail(contactInfo.email)
    .setAddress(physicalAddressToProto(contactInfo.contactAddress))
}

export function physicalAddressToProto(address) {
  if (!address) {
    return null
  }

  return new PhysicalAddress()
    .setAddressLine1(address.addressLine1)
    .setAddressLine2(address.addressLine2)
    .setCity(address.city)
    .setRegion(address.region)
    .setPostalCode(address.postalCode)
    .setCountry(address.country)
}
