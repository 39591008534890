import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required } from 'validx'
import { extractMessageFromError } from 'utils/errorUtil'
import { validateObject, validateSsn } from 'utils/validx-validators'
import moment from 'moment'
import { PhysicalAddressFormViewModel } from '../../PhysicalAddressForm/PhysicalAddressFormViewModel'
import { obscureSsn } from 'src/client/utils/stringUtil'

/**
 * View model for managing the document supporting item input.
 */
export class PersonalInfoSupportingItemInputViewModel {
  /**
   * The underlying item.
   *
   * @type {RequestedSupportingItem}
   */
  @observable item

  @observable showingDialog = false
  @observable firstName
  @observable middleInitial
  @observable lastName
  @observable dateOfBirth
  @observable personalTaxpayerIdentificationNumber
  @observable occupation
  @observable phoneNumber
  @observable email
  @observable physicalAddressVM = new PhysicalAddressFormViewModel()

  validation = validationContext(this, {
    firstName: [required('First name is required')],
    lastName: [required('Last name is required')],
    dateOfBirth: [required('Date of birth is required')],
    occupation: [required('Occupation is required')],
    personalTaxpayerIdentificationNumber: [
      required('SSN or ITIN is required'),
      validateSsn(),
    ],
    phoneNumber: [required('Phone number is required')],
    email: [required('Email is required')],
    physicalAddressVM: [validateObject('physicalAddressVM')],
  })

  constructor({
    item,
    flashMessageStore,
    individualProfileStore,
    infoGatheringStore,
    getCurrentJob,
  }) {
    /**
     * @type {RequestedSupportingItem}
     */
    this.item = item
    this.flashMessageStore = flashMessageStore
    this.individualProfileStore = individualProfileStore
    this.infoGatheringStore = infoGatheringStore
    this.getCurrentJob = getCurrentJob
  }

  activate = task.resolved(async () => {
    await this.fetchPersonalProfile()
  })

  /**
   * The current job.
   */
  @computed
  get currentJob() {
    return this.getCurrentJob()
  }

  /**
   * The current profile for the job if any
   */
  @computed
  get profile() {
    return this.individualProfileStore.getPersonalProfileVersion(
      this.item.input.profileId,
      this.item.input.profileVersion
    )
  }

  /**
   * The header description for the item.
   */
  @computed
  get headerDescription() {
    return [
      this.profile.personalInfo.dateOfBirth,
      obscureSsn(
        this.profile.personalInfo.personalTaxpayerIdentificationNumber
      ),
      this.profile.contactInfo.phoneNumber,
    ]
      .filter((line) => Boolean(line))
      .join(' / ')
  }

  /**
   * The summary of the contact information.
   */
  @computed
  get contactInfoLines() {
    return [
      this.profile.contactInfo.addressLine1,
      this.profile.contactInfo.addressLine2,
      this.cityStateZip,
      this.profile.contactInfo.country,
    ].filter((line) => Boolean(line))
  }

  /**
   * The summary of the city, state, and zip.
   */
  @computed
  get cityStateZip() {
    return [
      this.profile.contactInfo.city,
      this.profile.contactInfo.region,
      this.profile.contactInfo.postalCode,
    ]
      .filter((line) => Boolean(line))
      .join(', ')
  }

  /**
   * Shows the personal information dialog.
   */
  @action
  showDialog() {
    this.showingDialog = true
  }

  /**
   * Closes the personal information dialog.
   */
  @action
  closeDialog() {
    this.showingDialog = false
  }

  /**
   * Fetches the personal profile for the job.
   */
  @task
  async fetchPersonalProfile() {
    if (!this.item.input?.profileId) {
      return
    }

    await this.individualProfileStore
      .fetchPersonalProfileForJob(this.currentJob.id)
      .then((p) => this.fillProfile(p))
      .catch((e) => {
        this.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })
  }

  /**
   * Submits the personal information.
   *
   * @returns
   */
  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    try {
      const result = await this.individualProfileStore.savePersonalProfile(
        this.currentJob.workspaceId,
        {
          firstName: this.firstName,
          middleInitial: this.middleInitial,
          lastName: this.lastName,
          dateOfBirth: moment(this.dateOfBirth).format('MM/DD/yyyy'),
          personalTaxpayerIdentificationNumber:
            this.personalTaxpayerIdentificationNumber,
        },
        {
          email: this.email,
          phoneNumber: this.phoneNumber,
          contactAddress: this.physicalAddressVM.addressDto,
        },
        this.occupation
      )

      await this.infoGatheringStore.providePersonalInfo(
        this.currentJob.id,
        this.item,
        result.id,
        result.version
      )
      this.closeDialog()
    } catch (ex) {
      this.flashMessageStore.create({
        type: 'error',
        message: extractMessageFromError(ex),
      })
    }
  }

  /**
   * Assigns the fetched profile to the view model.
   */
  @action.bound
  fillProfile() {
    if (!this.profile) {
      return
    }

    this.firstName = this.profile.personalInfo?.firstName
    this.middleInitial = this.profile.personalInfo?.middleInitial
    this.lastName = this.profile.personalInfo?.lastName
    this.dateOfBirth = moment(this.profile.personalInfo?.dateOfBirth).format(
      'yyyy-MM-DD'
    )
    this.personalTaxpayerIdentificationNumber =
      this.profile.personalInfo.personalTaxpayerIdentificationNumber
    this.occupation = this.profile.occupation
    this.email = this.profile.contactInfo?.email
    this.phoneNumber = this.profile.contactInfo?.phoneNumber

    // Populate the physical address
    this.physicalAddressVM.parse(this.profile.contactInfo)
  }
}
