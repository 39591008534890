import { observable } from 'mobx'
import { Model } from 'libx'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export default class BusinessProfileVersion extends Model {
  @observable
  id = ''

  @observable
  version = 0

  @observable
  workspaceId = ''

  @observable
  ownerUserId = ''

  @observable
  businessInfo = null

  @observable
  contactInfo = null

  @observable
  createTime = new Timestamp()

  @observable
  updateTime = new Timestamp()

  parse(json) {
    return {
      id: json.id,
      version: json.version,
      workspaceId: json.workspaceId,
      ownerUserId: json.ownerUserId,
      businessInfo: this.toBusinessInfoDTO(json.businessInfo),
      contactInfo: this.toContactInfoDTO(json.contactInfo),
      createTime: Timestamp.fromDate(new Date(json.createTime)),
      updateTime: Timestamp.fromDate(new Date(json.updateTime)),
    }
  }

  toContactInfoDTO(info) {
    if (!info) {
      return info
    }

    return {
      email: info.email,
      phoneNumber: info.phoneNumber,
      addressLine1: info.address.addressLine1,
      addressLine2: info.address.addressLine2,
      city: info.address.city,
      region: info.address.region,
      postalCode: info.address.postalCode,
      country: info.address.country,
    }
  }

  toBusinessInfoDTO(info) {
    if (!info) {
      return info
    }

    return {
      businessName: info.businessName,
      dateOfIncorporation: info.dateOfIncorporation,
      employerIdentificationNumber: info.employerIdentificationNumber,
    }
  }
}
