import React from 'react'

import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogButton,
  DialogFooter,
  DialogContent,
} from 'components/Dialog'
import styles from './HandoffDialog.m.sass'
import ArrowIcon from 'assets/icons/arrow.i.svg'
import TickmarkIcon from 'assets/icons/tickmark.i.svg'
import IconSvg from 'components/IconSvg'
import { sessionReturnOptionsKey } from 'src/client/misc/MagicLinkClient'

export default observer(function HandoffDialog({ vm, workspace }) {
  const workspaceIcon = workspace?.branding?.logos?.icon
  const workspaceName = workspace?.name
  const returnOptionsPayload = sessionStorage.getItem(sessionReturnOptionsKey)
  const payload = returnOptionsPayload ? JSON.parse(returnOptionsPayload) : null
  const returnOptions = payload ? payload.returnOptions : null
  const returnUrl = returnOptions?.url

  return (
    <Modal
      isOpened={vm.showing}
      dismissOnOutsideClick={false}
      hideOverlayDismiss={true}
      className={styles.handoffDialogModal}
    >
      <Dialog className={styles.handoffDialog}>
        <DialogContent className={styles.dialogContent}>
          <div className={styles.handoffDialogHeaderContainer}>
            <WorkspaceTransition workspaceIcon={workspaceIcon} />

            <span className={styles.workspaceHandoffDialogDescription}>
              {workspaceName} works with Taxfyle to help you file your taxes
            </span>
          </div>

          <div className={styles.handoffDialogBody}>
            <h2 className={styles.handoffDialogBodyTitleText}>
              Expert tax filing that gets you
              <br /> everything you’re owed
            </h2>

            <HandoffChecklist />
          </div>
        </DialogContent>

        <DialogFooter className={styles.handoffDialogFooter}>
          <DialogButton
            primary
            className={styles.handoffDialogPrimaryButton}
            onClick={() => vm.close()}
          >
            Get started
          </DialogButton>

          {returnUrl && (
            <DialogButton
              className={styles.handoffDialogCancelButton}
              onClick={() => (window.location.href = returnUrl)}
            >
              Cancel
            </DialogButton>
          )}

          <p className={styles.handoffDialogAgreementText}>
            By continuing, you acknowledge that you have reviewed and agree to
            Taxfyle's{' '}
            <a
              href="https://www.taxfyle.com/policy/terms"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.handoffDialogLink}
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href="https://www.taxfyle.com/policy/privacy-policy-august-10-2021"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.handoffDialogLink}
            >
              Privacy Policy
            </a>
            .
          </p>
        </DialogFooter>
      </Dialog>
    </Modal>
  )
})

const ArrowStack = () => {
  return (
    <div className={styles.arrowIconsStack}>
      <IconSvg size={24} className={styles.arrowIconLightGrey}>
        <ArrowIcon />
      </IconSvg>
      <IconSvg size={24} className={styles.arrowIconMediumGrey}>
        <ArrowIcon />
      </IconSvg>
      <IconSvg size={24} className={styles.arrowIconHeavyGrey}>
        <ArrowIcon />
      </IconSvg>
    </div>
  )
}

const WorkspaceTransition = ({ workspaceIcon }) => {
  return (
    <div className={styles.workspaceContainer}>
      <div className={styles.handoffDialogHeaderIcon}>
        <img
          className={styles.workspaceIconLogo}
          src={workspaceIcon}
          alt="Workspace Logo"
        />
      </div>

      <ArrowStack />

      <div className={styles.handoffDialogHeaderIcon}>
        <img
          className={styles.workspaceIconLogo}
          src={require('assets/img/worklayer-logo-icon.png')}
          alt="Taxfyle Logo"
        />
      </div>
    </div>
  )
}

const HandoffChecklist = () => {
  return (
    <div className={styles.handoffDialogBodyChecklistContainer}>
      <div className={styles.handoffDialogBodyChecklistItem}>
        <IconSvg size={16}>
          <TickmarkIcon />
        </IconSvg>
        <div className={styles.dividerRight} />
        <p className={styles.checklistItemText}>
          <span className={styles.boldText}>
            Accurate and secure tax filing
          </span>{' '}
          by licensed CPAs and EAs.
        </p>
      </div>
      <div className={styles.handoffDialogBodyChecklistItem}>
        <IconSvg size={16}>
          <TickmarkIcon />
        </IconSvg>
        <div className={styles.dividerRight} />
        <p className={styles.checklistItemText}>
          <span className={styles.boldText}>Maximize your refund</span> with
          expert guidance every step of the way.
        </p>
      </div>
      <div className={styles.handoffDialogBodyChecklistItem}>
        <IconSvg size={16}>
          <TickmarkIcon />
        </IconSvg>
        <div className={styles.dividerRight} />
        <p className={styles.checklistItemText}>
          <span className={styles.boldText}>Personalized tax support</span> with
          no hidden fees.
        </p>
      </div>
    </div>
  )
}
