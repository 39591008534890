import { Store } from 'libx'
import { action, observable } from 'mobx'

export default class JobTaxAssistantGreetingDialogStore extends Store {
  @observable
  isOpened = false

  constructor() {
    super(...arguments)
    this._queue = new Set()
  }

  /**
   * Queue the greeting dialog for a job.
   */
  @action.bound
  queueForJob(jobId) {
    this._queue.add(jobId)
  }

  /**
   * Open the greeting dialog for a queued job.
   */
  @action.bound
  open(jobId) {
    if (!this._queue.delete(jobId)) {
      return
    }

    this.isOpened = true
  }

  /**
   * Close the greeting dialog.
   */
  @action.bound
  close() {
    this.isOpened = false
  }
}
