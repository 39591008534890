import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import memoize from 'memoizee'
import { computed, observable, runInAction, when } from 'mobx'
import { task } from 'mobx-task'

export default class ProjectServiceTypeState {
  stepId = 'ProjectServiceType'
  title = T(
    'Web.Onboarding.LegendServiceTypeSelect.Header',
    'Hi, how can we help you?'
  )

  description = T(
    'Web.Onboarding.LegendServiceTypeSelect.Description',
    'Please select a service type from the options below'
  )

  trackingId = 'Onboarding - Project Service Type Selection'

  @observable legendServiceType = undefined

  constructor({ wizard }) {
    this.wizard = wizard
    this.rootStore = wizard.rootStore
    this.activate = this.activate.wrap((fn) =>
      memoize(fn, { promise: true, length: 0 })
    )
    this.rootStore.sessionStore.onWorkspaceSelected(() => this.activate.clear())

    if (this.wizard.job) {
      this.legendServiceType = this.wizard.legends.find(
        (i) => i.id === this.wizard.job.legendId
      ).service_type
    }

    if (this.paramServiceType) {
      this.legendServiceType = this.paramServiceType
    }
  }

  @computed
  get relevant() {
    return (
      this.wizard.getStep('ProjectType').filteredLegends.length > 1 &&
      this.wizard.job === null &&
      this.paramServiceType === undefined
    )
  }

  @computed
  get hideBackButton() {
    const teamSelectStep = this.wizard.getStep('TeamSelect')
    return !teamSelectStep || !teamSelectStep.relevant
  }

  @computed
  get filteredTaxLegends() {
    return this.filteredLegends.filter((x) => x.service_type === 'TAX')
  }

  @computed
  get filteredConsultationLegends() {
    return this.filteredLegends.filter((x) => x.service_type === 'CONSULTATION')
  }

  @computed
  get filteredBookkeepingLegends() {
    return this.filteredLegends.filter((x) => x.service_type === 'BOOKKEEPING')
  }

  @computed
  get filteredMiscLegends() {
    return this.filteredLegends.filter((x) => x.service_type === 'MISC')
  }

  @computed
  get filteredLegends() {
    const workspaceId = this.rootStore.sessionStore.workspace.id
    const teamStep = this.wizard.getStep('TeamSelect')
    if (!teamStep || !teamStep.relevant || !teamStep.team) {
      return this.legends.filter(
        (x) =>
          !x.team_id &&
          x.workspace_id === workspaceId &&
          x.service_type !== undefined
      )
    }

    return this.legends.filter((l) => {
      return (
        l.service_type !== undefined &&
        (!l.team_id ||
          l.team_id === teamStep.team.id ||
          l.team_id === teamStep.team.slug) &&
        l.workspace_id === workspaceId
      )
    })
  }

  @computed
  get paramServiceType() {
    return MapParamServiceType(this.wizard.paramServiceType)
  }

  @computed
  get legends() {
    return this.wizard.legends
  }

  @task
  async activate() {
    await when(() => this.rootStore.sessionStore.workspace)
  }

  @task.resolved
  selectLegendType(type) {
    this.legendServiceType = type

    if (this.legendServiceType !== undefined) {
      runInAction(() => {
        this.wizard.next()
      })
    }
  }
}

function MapParamServiceType(paramServiceType) {
  switch (paramServiceType) {
    case 'tax':
    case 'taxes':
      return 'TAX'
    case 'consultation':
    case 'advisory':
      return 'CONSULTATION'
    case 'bookkeeping':
      return 'BOOKKEEPING'
    case 'misc':
    case 'other':
      return 'MISC'
    default:
      return undefined
  }
}
