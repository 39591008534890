import { Model } from 'libx'
import { computed, observable } from 'mobx'
import { maybeParseDate } from 'utils/dateUtil'

export class JobTaxAssistant extends Model {
  @observable
  jobId = ''

  @observable
  replyStatus = 'IDLE'

  @observable
  active = false

  @observable
  externalReplyId = undefined

  @observable
  dateModified = undefined

  get displayName() {
    return 'Luis AI'
  }

  get avatar() {
    return require('../assets/img/img_luis.svg')
  }

  @computed
  get isTyping() {
    return this.replyStatus === 'PROCESSING'
  }

  isDepartingConversationMessage(message) {
    return (
      !this.active && this.externalReplyId === message.content.externalReplyId
    )
  }

  parse(json) {
    return {
      jobId: json.job_id,
      replyStatus: json.reply_status,
      externalReplyId: json.external_reply_id,
      active: json.active,
      dateModified: maybeParseDate(json.update_time),
    }
  }
}
