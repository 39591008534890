import { Store } from 'libx'
import PersonalProfileVersion from '../domain/individual-profile/PersonalProfileVersion'
import SpousalProfileVersion from '../domain/individual-profile/SpousalProfileVersion'

export default class IndividualProfileStore extends Store {
  personalProfiles = this.collection({
    model: PersonalProfileVersion,
    getDataId: (data) => `${data.id}:${data.version}`,
    getModelId: (model) => `${model.id}:${model.version}`,
  })

  spousalProfiles = this.collection({
    model: SpousalProfileVersion,
    getDataId: (data) => `${data.id}:${data.version}`,
    getModelId: (model) => `${model.id}:${model.version}`,
  })

  constructor({ rootStore }) {
    super({ rootStore })
    this.api = rootStore.api
  }

  /**
   * Gets a profile's specific version.
   *
   * @param {*} profileId
   * @param {*} profileVersion
   * @returns
   */
  getPersonalProfileVersion(profileId, profileVersion) {
    return this.personalProfiles.get(`${profileId}:${profileVersion}`)
  }

  /**
   * Gets a spousal profile's specific version.
   *
   * @param {*} profileId
   * @param {*} profileVersion
   * @returns
   */
  getSpousalProfileVersion(profileId, profileVersion) {
    return this.spousalProfiles.get(`${profileId}:${profileVersion}`)
  }

  /**
   * Fetches the personal profile for the current user.
   *
   * @returns
   */
  async fetchPersonalProfile() {
    return this.rootStore.api.individualProfile
      .getPersonalProfile(this.rootStore.sessionStore.workspace.id)
      .then((p) => this.personalProfiles.set(p))
  }

  /**
   * Fetche the personal profile for a job.
   *
   * @param {*} jobId
   * @returns
   */
  async fetchPersonalProfileForJob(jobId) {
    return this.rootStore.api.individualProfile
      .getPersonalProfileForJob(jobId)
      .then((p) => this.personalProfiles.set(p))
  }

  /**
   * Saves the personal profile.
   *
   * @param {*} workspaceId
   * @param {*} personalInfo
   * @param {*} contactInfo
   * @param {*} occupation
   * @returns
   */
  async savePersonalProfile(
    workspaceId,
    personalInfo,
    contactInfo,
    occupation
  ) {
    return this.rootStore.api.individualProfile
      .savePersonalProfile(workspaceId, personalInfo, contactInfo, occupation)
      .then(async (p) => this.fetchPersonalProfile(workspaceId))
  }

  /**
   * Fetches the spousal profile for the current user.
   *
   * @returns
   */
  async fetchSpousalProfile() {
    return this.rootStore.api.individualProfile
      .getSpousalProfile(this.rootStore.sessionStore.workspace.id)
      .then((p) => this.spousalProfiles.set(p))
  }

  /**
   * Fetche the spousal profile for a job.
   *
   * @param {*} jobId
   * @returns
   */
  async fetchSpousalProfileForJob(jobId) {
    return this.rootStore.api.individualProfile
      .getSpousalProfileForJob(jobId)
      .then((p) => this.spousalProfiles.set(p))
  }

  /**
   * Saves the spousal profile.
   *
   * @param {*} workspaceId
   * @param {*} personalInfo
   * @param {*} contactInfo
   * @param {*} occupation
   * @returns
   */
  async saveSpousalProfile(workspaceId, personalInfo, contactInfo, occupation) {
    return this.rootStore.api.individualProfile
      .saveSpousalProfile(workspaceId, personalInfo, contactInfo, occupation)
      .then(async (p) => this.fetchSpousalProfile(workspaceId))
  }
}
