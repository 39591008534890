import { observable } from 'mobx'
import { Model } from 'libx'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export default class PersonalProfileVersion extends Model {
  @observable
  id = ''

  @observable
  version = 0

  @observable
  workspaceId = ''

  @observable
  ownerUserId = ''

  @observable
  personalInfo = null

  @observable
  occupation = ''

  @observable
  contactInfo = null

  @observable
  createTime = new Timestamp()

  @observable
  updateTime = new Timestamp()

  parse(json) {
    return {
      id: json.id,
      version: json.version,
      workspaceId: json.workspaceId,
      ownerUserId: json.ownerUserId,
      personalInfo: this.toPersonalInfoDTO(json.personalInfo),
      occupation: json.occupation,
      contactInfo: this.toContactInfoDTO(json.contactInfo),
      createTime: Timestamp.fromDate(new Date(json.createTime)),
      updateTime: Timestamp.fromDate(new Date(json.updateTime)),
    }
  }

  toContactInfoDTO(contactInfo) {
    if (!contactInfo) {
      return contactInfo
    }
    return {
      email: contactInfo.email,
      phoneNumber: contactInfo.phoneNumber,
      addressLine1: contactInfo.address.addressLine1,
      addressLine2: contactInfo.address.addressLine2,
      city: contactInfo.address.city,
      region: contactInfo.address.region,
      postalCode: contactInfo.address.postalCode,
      country: contactInfo.address.country,
    }
  }

  toPersonalInfoDTO(personalInfo) {
    if (!personalInfo) {
      return personalInfo
    }
    return {
      firstName: personalInfo.firstName,
      middleInitial: personalInfo.middleInitial,
      lastName: personalInfo.lastName,
      dateOfBirth: personalInfo.dateOfBirth,
      personalTaxpayerIdentificationNumber:
        personalInfo.personalTaxpayerIdentificationNumber,
    }
  }
}
